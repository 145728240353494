import React, { FC, useEffect, useMemo } from 'react';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import Container from '@mui/material/Container';

import Layout from 'src/template/layout';
import './privacy.scss';
import { getQueryVariable } from 'src/utils';
import { styled } from '@mui/material/styles';

interface Props {
  location: Location;
}

const PageWrapper = styled(Container)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6,
  & strong {
    font-family: "Poppins", "Pretendard", "Noto Sans KR", system-ui, sans-serif;
  }
`;

const PrivacyNotice = styled('p')`
  font-size: 1rem;
  color: black;
  margin-top: -100px;
  margin-bottom: -120px;
  font-weight: bold;

  a {
    color: #007bff;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

// markup
const PrivacyPage: FC<Props> = ({ location }) => {
  const version = getQueryVariable(location.search, 'v');

  const { content: { edges } } = useStaticQuery<{
    content: {
      edges: {
        node: {
          relativePath: string;
          childMarkdownRemark: {
            id: string;
            html: string;
          };
        };
      }[];
    };
  }>(graphql`
    query {
      content: allFile(filter: { relativePath: { regex: "/^privacy/" } }) {
        edges {
          node {
            relativePath
            childMarkdownRemark {
              id
              html
            }
          }
        }
      }
    }
  `);

  const expectedPath = useMemo(() => `privacy${version ? `-${version}` : ''}.md`, [version]);

  const edge = useMemo(() =>
    edges.find(({ node: { relativePath }}) => relativePath === expectedPath) ??
    edges.find(({ node: { relativePath }}) => relativePath === 'privacy.md') ?? edges[0],
    [edges, expectedPath],
  );

  useEffect(() => {
    const handleLinkClick = (e) => {
      const href = e.target.getAttribute("href");
      if (href.startsWith('/')) {
        // 내부링크
        e.preventDefault();
        navigate(href); // Gatsby의 navigate 함수를 사용하여 이동하기
      }
    };

    const links = document.querySelectorAll("article a");
    links.forEach((link) => link.addEventListener("click", handleLinkClick));

    return () => {
      links.forEach((link) => link.removeEventListener("click", handleLinkClick));
    };
  }, []);

  return (
    <Layout>
      <PageWrapper>
        <PrivacyNotice>2024년 5월 31일부터 <a href="/terms/privacy-policy.html">새로운 개인정보 처리방침</a>이 시행됩니다. 본 개인정보 처리방침은 그 이전까지만 적용됩니다.</PrivacyNotice>

        <article
          className="privacy-content container-md"
          dangerouslySetInnerHTML={{
            __html: edge.node.childMarkdownRemark.html,
          }}
        />
      </PageWrapper>
    </Layout>
  );
};

export default PrivacyPage;
